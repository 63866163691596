export const ACTIVECAMPAIGN_HOME_URL = 'https://activecampaign.com';
export const HOME_PAGE_URL = 'https://fundjournalism.org/';
export const CORE_UPGRADE_URL = 'https://fundjournalism.org/i-want-revengine-core/';
export const FAQ_URL = 'https://news-revenue-hub.atlassian.net/servicedesk/customer/portal/11/article/2195423496';
export const EMAIL_KB_URL = 'https://news-revenue-hub.atlassian.net/servicedesk/customer/portal/11/article/2672656386';
export const HELP_URL = 'https://fundjournalism.org/news-revenue-engine-help/';
export const KNOWLEDGE_BASE_URL =
  'https://news-revenue-hub.atlassian.net/servicedesk/customer/portal/11/article/2195423496';
export const PLUS_UPGRADE_URL = 'https://fundjournalism.org/i-want-revengine-plus/';
export const PRICING_URL = 'https://fundjournalism.org/pricing/';
export const PRIVACY_POLICY_URL = 'https://fundjournalism.org/faq/privacy-policy/';
export const TS_AND_CS_URL = 'https://fundjournalism.org/faq/terms-of-service/';
export const FUNDJOURNALISM_404_REDIRECT = 'https://fundjournalism.org/?utm_campaign=404#donate';
